<template>
  <v-row align="center">
    <v-col cols="auto">
      <div class="mb-4">
        <div class="overline">
          <span v-t="'payment.Recurring payments:'" />
        </div>
        <v-chip
          class="ma-2"
          :color="info.autoPayment ? 'success' : 'error'"
          text-color="white"
        >
          <v-avatar left>
            <v-icon>
              {{ info.autoPayment ? checkedCircle : closedCircle }}
            </v-icon>
          </v-avatar>
          {{
            $t(
              `payment.${camelize(
                info.autoPayment
                  ? recurringPaymentsEnabled
                  : recurringPaymentsDisabled
              )}`
            )
          }}
        </v-chip>
      </div>
    </v-col>
    <v-col
      v-if="!info.autoPayment"
      cols="auto"
    >
      <v-alert
        color="info"
        dense
        :icon="infoIcon"
        outlined
        class="mb-4"
      >
        <small class="ml-2">
          {{
            $t(
              'payment.activatingRecurringPaymentsIsPossibleViaPaymentGateOrderCheckout'
            )
          }}
        </small>
      </v-alert>
    </v-col>
    <v-col
      v-if="info.autoPayment"
      cols="6"
    >
      <div class="mb-4">
        <div class="overline">
          <span v-t="'payment.paymentMethod'" />
        </div>
        <material-recurring-payments-method-info :method="info.method" />
      </div>
      <div class="mb-4">
        <div class="overline">
          <span v-t="'payment.nextPayment'" />
        </div>
        {{
          info.autoPaymentDate +
            ' ' +
            $tc('payment.(period: in months)', info.periodInMonths)
        }}
      </div>
      <div class="mb-4">
        <div class="overline">
          <span v-t="'payment.amount'" />
        </div>
        {{ info.currency + ' ' + info.amount }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  INFO_ICON,
  RECURRING_PAYMENTS_ENABLED,
  RECURRING_PAYMENTS_DISABLED,
  CHECKED_CIRCLE_ICON,
  CLOSED_CIRCLE_ICON
} from '../../../constants/app'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    infoIcon: INFO_ICON,
    checkedCircle: CHECKED_CIRCLE_ICON,
    closedCircle: CLOSED_CIRCLE_ICON,
    recurringPaymentsEnabled: RECURRING_PAYMENTS_ENABLED,
    recurringPaymentsDisabled: RECURRING_PAYMENTS_DISABLED
  }),
  methods: {
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
